@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  &:before{
    content:'';
    content: "";
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #070502, #070502);
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.text-shadow{
  text-shadow: 0px 2px 0px rgb(0 0 0 / 30%);
}

.lefto {
  left:-200px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.text-gradient {
  background: linear-gradient(to right, #00b7ff, #6445ff); /* Replace with your 'monet-blue' and 'monet-purple' colors */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.navBarCont{
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: flex;
  align-items: center;
  padding: 0 1.5em;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}

.image-container {

  width: 100%;

  > div {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

@media screen and (max-width: 640px) { 
  .xxl {
    font-size: 8rem;
    line-height: 1;
  }
}

@media screen and (min-width: 640px) { 
  .xxl {
    font-size: 13rem;
    line-height: 1;
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@keyframes pulse-slow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.animate-pulse-slow {
  animation: pulse-slow 3s ease-in-out infinite;
}


.timeline-container {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

.timeline-container > div {
  overflow: visible;
}

@layer components {
  .glass-card {
    @apply backdrop-blur-xl bg-white/5 border border-white/10 shadow-[0_4px_20px_rgba(0,0,0,0.4)];
  }
  
  .neon-border {
    @apply relative;
  }
  
  .neon-border::before {
    content: "";
    @apply absolute -inset-[1px] rounded-lg bg-gradient-to-r from-monet-blue to-monet-purple opacity-70 blur-[5px] transition-all duration-1000 group-hover:opacity-100 group-hover:blur-[10px];
    z-index: -1;
  }
  
  .text-gradient {
    @apply bg-gradient-to-r from-monet-blue to-monet-purple bg-clip-text text-transparent;
  }
  
  .animated-gradient-text {
    background: linear-gradient(-45deg, #3B82F6, #8B5CF6, #22D3EE, #4F46E5);
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradient-animation 6s ease infinite;
  }

  .animated-gradient-bg {
    background: linear-gradient(-45deg, #3B82F6, #8B5CF6, #22D3EE, #4F46E5);
    background-size: 300%;
    animation: gradient-animation 3s ease infinite;
  }
  
  
  @keyframes gradient-animation {
    0% { background-position: 0% 50% }
    50% { background-position: 100% 50% }
    100% { background-position: 0% 50% }
  }
  
  .grid-pattern {
    background-image:
      linear-gradient(rgba(59, 130, 246, 0.1) 1px, transparent 1px),
      linear-gradient(90deg, rgba(59, 130, 246, 0.1) 1px, transparent 1px);
    background-size: 40px 40px;
  }
  
  .hover-lift {
    @apply transition-all duration-300 hover:-translate-y-1 hover:shadow-lg;
  }
  
  .slide-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .slide-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .shimmer {
    position: relative;
    overflow: hidden;
  }
  
  .shimmer::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      90deg, 
      rgba(255, 255, 255, 0) 0%, 
      rgba(255, 255, 255, 0.05) 50%, 
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
}

@layer base {
  :root {
    --background: 222 47% 11%;
    --foreground: 210 40% 98%;

    --card: 222 47% 11%;
    --card-foreground: 210 40% 98%;

    --popover: 222 47% 11%;
    --popover-foreground: 210 40% 98%;

    --primary: 221 83% 53%;
    --primary-foreground: 210 40% 98%;

    --secondary: 217 33% 18%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217 33% 18%;
    --muted-foreground: 215 20% 65%;

    --accent: 258 90% 66%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 210 40% 98%;

    --border: 217 33% 18%;
    --input: 217 33% 18%;
    --ring: 224 76% 48%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border selection:bg-monet-blue/20 selection:text-white;
  }
  
  html {
    @apply scroll-smooth;
  }
  
  body {
    @apply bg-monet-darker text-foreground antialiased;
    background: radial-gradient(circle at 50% 0%, #1E3A8A 0%, #0F172A 35%, #030712 100%);
    background-attachment: fixed;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-display tracking-tight;
  }
}



@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}